<template>
  <div>
    <van-popup
      v-model:show="show"
      position="bottom"
      @close="onConfirm"
      :close-on-click-overlay="false"
    >
      <van-picker
        title="请选择银行卡"
        :columns="bankList"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { Picker, Popup } from 'vant'
export default {
  name: 'BankPicker',
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  data() {
    return {
      show: true,
      bankList: [],
    }
  },
  created() {
    this.$http('/merchant/wxBank').then((res) => {
      const data = res.data
      const list = []
      for (var i in data) {
        list.push({ text: data[i], value: i })
      }
      this.bankList = list
    })
  },
  methods: {
    onConfirm(res) {
      this.$emit('confirm', res)
    },
    onCancel() {
      this.$emit('confirm', undefined)
    },
  },
}
</script>
